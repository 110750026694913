@import url("https://fonts.googleapis.com/css2?family=The+Nautigal:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap");

.App {
  background-color: rgba(4, 70, 192, 0.123) !important;
}

.section-divider-color {
  background-color: rgba(250, 250, 250, 0.699) !important;
}

body {
  z-index: 1;
  line-height: unset !important;
}
.cab-bg-image {
  background-image: url("./assets/images/cab.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.sticky + .content {
  padding-top: 60px;
}

.nav-bar-bg {
  background-color: rgb(58, 58, 58) !important;
}
.nav-btn-padding {
  padding: 0px !important;
}

.card-style {
  background-image: linear-gradient(315deg, #e0e1e2 0%, #a5aab6 74%);
  box-shadow: 2px 2px 0px darkgray !important;
}

.card-style-2 {
  background-image: linear-gradient(315deg, #b3b3b3 0%, #93969e 74%);
}

.card-style-3 {
  background-image: linear-gradient(315deg, #ebebeb 0%, #c5c9d3 74%);
}

.card-style-4 {
  background-image: linear-gradient(315deg, #af900441 0%, #a88a0244 74%);
}

.card-style-5 {
  background-image: linear-gradient(0deg, #afafaf85 30%, #464647da 60%);
}

.btn-style-1 {
  background-image: linear-gradient(315deg, #b3b3b3 0%, #93969e 74%);
}

.accent-color {
  background-color: rgb(255, 230, 0) !important;
}

.height-fixed {
  height: calc(100vh + 53px);
}

.card-image {
  height: 200px !important;
  width: 100% !important;
}

.mui-btn {
  position: unset !important;
}

.dev-icon {
  font-size: 6em !important;
}

.dev-img-lg {
  height: 50px;
  width: 50px;
}

.dev-img-sm {
  height: 20px;
  width: 20px;
}

.name-header {
  font-size: 100px;
  font-family: "Tangerine", cursive;
  text-shadow: 2px 2px black;
  color: rgb(233, 221, 112);
}

.title-header {
  font-size: 50px;
  font-family: "The Nautigal", cursive;
  text-shadow: 2px 2px black;
  color: rgb(233, 221, 112);
}

.landing-tangerine {
  font-family: "Tangerine", cursive;
  font-weight: bolder;
  text-shadow: 1px 3px 5px rgb(0, 0, 0);
  color: rgb(218, 213, 174);
  font-size: 40px;
}

.landing-yellow-tangerine {
  font-family: "Tangerine", cursive;
  font-weight: bolder;
  text-shadow: 1px 3px 5px rgb(0, 0, 0);
  color: rgb(218, 213, 174);
  font-size: 40px;
}

.text-shadow {
  text-shadow: 1px 3px 5px rgb(0, 0, 0);
}

.avatar {
  height: 100px;
  width: 100px;
  box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.582);
}

.card-highlight {
  box-shadow: 1px 5px 20px rgb(109, 109, 109);
}

.badge {
  color: rgb(252, 248, 218) !important;
}

.nav-icon-color {
  color: rgb(185, 177, 97);
}
